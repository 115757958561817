export const useScrollTop = () => {
  const { $store } = useNuxtApp();
  const showScrollToTopButton = ref(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  onMounted(() => {
    const navbar = document.querySelector(".nav-main");
    if (navbar) {
      window.onscroll = () => {
        showScrollToTopButton.value = window.scrollY > 500;
        $store.dispatch("navWhite", window.scrollY > navbar.clientHeight);
      };
    }
  });

  return {
    showScrollToTopButton,
    scrollToTop,
  };
};
